import React from "react";
import map from "../assets/imgs/mapa.webp";

const Venta = () => {
  return (
    <section
      id="dondePardalis"
      className="h-[100vh] bg-ventasMobile-pattern bg-cover bg-center flex flex-col lg:flex-row-reverse items-center justify-around relative z-[0]"
    >
      <div className="flex flex-col items-center md:w-[40%] lg:w-[50%]">
        <h4 className="text-[2.5rem] 2xl:text-[3.5rem] text-[#962125] font-cristone">
          Puntos de venta
        </h4>
        <p className="text-white font-bahnschrift lg:text-[1.5rem] text-center">
          Tu Pardalis está más cerca de lo que pensás ¡buscalo!
        </p>
      </div>
      <div className="w-[90%] h-[50%] md:w-[50%] md:pl-10 lg:w-[50%] lg:h-[50%] relative z-[0] flex justify-center items-center">
        <a
          className="cursor-pointer object-cover h-[100%]"
          href="https://www.google.com/maps/@-32.2269678,-58.1621187,14z/data=!4m2!11m1!2snUxZS4irRu-B8RmvkonDgA?entry=ttu"
          target="_blank"
          rel="noreferrer"
        >
          <img src={map} alt="mapa" />
        </a>
      </div>
    </section>
  );
};

export default Venta;
