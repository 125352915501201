import React from "react";
import logoMobile from "../assets/imgs/logoMobile.webp";
import logoMobile2 from "../assets/imgs/logoMobile2.webp";

const Home = () => {
  return (
    <section
      id="home"
      className="h-[100vh] w-[100%] flex flex-col md:flex-row gap-10 items-center justify-center md:justify-around bg-homeMobile-pattern md:bg-home-pattern bg-center bg-cover"
    >
      <img
        fetchpriority="high"
        className="block md:hidden"
        src={logoMobile2}
        alt="logo"
      />
      <img
        fetchpriority="high"
        className="hidden md:block"
        src={logoMobile}
        alt="logo"
      />
      <h1
        data-aos="fade-left"
        data-aos-duration="300"
        className={`text-white text-[.8rem] md:text-[1.5rem] text-center font-blatan uppercase tracking-[.3rem]`}
      >
        Bienvenidos al lado salvaje <br /> del fernet
      </h1>
    </section>
  );
};

export default Home;
