import React from "react";
import hierbasPardalisLogo from "../assets/imgs/hierbasPardalisLogo.png";

const Hierbas = () => {
  return (
    <section className="h-[100vh] bg-hierbasMobile-pattern md:bg-hierbasBg-pattern md:bg-center bg-cover bg-top flex flex-col justify-center items-center relative z-[-3]">
      <div className="flex flex-col md:flex-col-reverse items-center justify-center md:justify-start mt-20 gap-6 bg-gradient-to-b from-transparent to-black/70 h-[100%] w-full">
        <img
          data-aos="fade-up"
          data-aos-duration="500"
          src={hierbasPardalisLogo}
          alt=""
          className="h-[4rem] md:h-[8rem] md:mb-40"
        />
        <p
          data-aos="fade-up"
          data-aos-duration="500"
          className="text-white w-[60%] font-bahnschrift text-center mt-6 md:w-[40%] md:text-[1.3rem]"
        >
          Angelica - Cedrón - Cáscaras de naranja - Cáscaras de limón -
          Cardamomo - Carqueja - Mirra - Canela - Nencia - Quina - Menta
          peperina - Laurel - Manzanilla
        </p>
      </div>
    </section>
  );
};

export default Hierbas;
